@import url(//fonts.googleapis.com/css?family=Arizonia);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ******************************* Variables *******************************/
/* Button Colors */
/* Shades of Gray */
/* Resmark Brand Colors */
/* Form Elements Colors */
/* Z-index Variables */
/* ******************************* Mixins *******************************/
body {
  margin: 0;
  padding: 0;
  background: #F0F4F5;
  min-width: auto;
}
@media only screen and (max-width: 767px) {
  body {
    background-color: #FFFFFF !important;
  }
}

.ui.header.signature {
  font-family: Arizonia, Helvetica, Arial, sans-serif !important;
  font-size: 220%;
}
@media only screen and (max-width: 767px) {
  .ui.header.signature {
    font-size: 170%;
  }
}

.initials {
  font-size: 1.2rem !important;
  color: inherit !important;
  cursor: pointer !important;
  background: none !important;
  border: none !important;
}

.ui.link {
  cursor: pointer;
}

.ui.button {
  text-transform: uppercase !important;
}

.not-display-checkbox::before, .not-display-checkbox::after {
  content: none !important;
}

.field.ui.checkbox.invalid-checkbox > input[type=checkbox] {
  border: 1px solid #e80404;
  border-radius: 3px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  opacity: 1 !important;
}

.react-tel-input {
  width: 100%;
}
.react-tel-input input {
  padding: 0.67857143em 1em 0.67857143em 44px !important;
  height: auto !important;
}
.react-tel-input .flag-dropdown .selected-flag {
  height: 100%;
  z-index: 1;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}

.hover:hover {
  background: #fffae6;
}

.main-header {
  margin-bottom: 20px;
  background: #ffffff;
}
.main-header .logo {
  width: 200px !important;
  height: auto !important;
}
.main-header .ui.header {
  color: #EB7B1E;
  padding: 10px 0 0;
}
.main-header .ui.vertical.segment {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
}

#logo {
  max-height: 90px;
}
@media only screen and (max-width: 767px) {
  #logo {
    max-height: 60px;
  }
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 10px 5px;
  background-color: #15242e;
}
footer .logo {
  width: 200px !important;
  height: auto !important;
}
footer div {
  color: #E5E5E5;
}

.footer-success-message.desktop {
  position: absolute !important;
  bottom: 70px;
  left: 50%;
  text-align: center;
  transform: translate(-50%, 0);
  width: 80%;
  max-width: 800px;
}

.disabled-link {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .footer-success-message.desktop {
    display: none;
  }

  .footer-success-message.mobile {
    position: initial !important;
    max-width: 800px;
    text-align: center;
    width: 100%;
    transform: translate(0, 0) !important;
  }
}
.ui.header.form-header {
  background: #F0F4F5;
  color: rgba(0, 0, 0, 0.6);
}
.ui.header.form-header .ui.center.aligned.header {
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.ui.container {
  max-width: 800px !important;
}

.neg-margin-top {
  margin-top: -12px !important;
}

.neg-margin-bottom {
  margin-bottom: -12px !important;
}

.neg-margin-left {
  margin-left: -12px !important;
}

.neg-margin-right {
  margin-right: -12px !important;
}

.margin-top {
  margin-top: 20px !important;
}

.margin-bottom {
  margin-bottom: 20px !important;
}

.margin-left {
  margin-left: 12px !important;
}

.margin-none {
  margin: 0 !important;
}

.margin-right {
  margin-right: 12px !important;
}

.padding-top {
  padding-top: 12px !important;
}

.padding-bottom {
  padding-bottom: 12px !important;
}

.padding-left {
  padding-left: 12px !important;
}

.padding-right {
  padding-right: 12px !important;
}

.padding {
  padding: 12px !important;
}

.padding-none-top {
  padding-top: 0 !important;
}

.padding-none-right {
  padding-right: 0 !important;
}

.padding-none-bottom {
  padding-bottom: 0 !important;
}

.padding-none-left {
  padding-left: 0 !important;
}

.padding-none {
  padding: 0 !important;
}

.ui.message.half-padding {
  padding: 0.5rem 1.5rem;
}

.txt-right {
  text-align: right !important;
}

.txt-left {
  text-align: left !important;
}

.txt-center {
  text-align: center !important;
}

.width-fluid {
  display: block;
  width: 100%;
}

.ui.fluid.button {
  display: inline-block;
}

.flag-dropdown {
  display: none;
}

.react-tel-input input {
  padding-left: 1em !important;
}

.ui.secondary.button {
  color: #2185d0;
  background-color: transparent;
  border: 1px solid #2185d0;
}
.ui.secondary.button:hover {
  background-color: #2185d0;
  color: #ffffff;
}

i.huge.icon, i.huge.icons {
  text-align: left;
}

.ui.items:not(.unstackable) > .item {
  flex-direction: row;
  align-items: center;
}

.calendar.icon {
  float: right;
  position: relative;
  top: -27px;
  right: 10px;
}

.ui.labeled.icon.button {
  padding-left: 3.27143em !important;
}

.participant-box {
  min-height: 210px;
  background: #F0F4F5 !important;
}
.participant-box .icon {
  color: #15242e;
}

.language-box {
  background: #F0F4F5 !important;
  box-shadow: none;
}

.centeredButton {
  min-width: 250px;
}

#participantSelector {
  margin-bottom: 1rem !important;
}
#participantSelector .qty input {
  border-radius: 0 !important;
  border-color: #C9CACA !important;
  width: 60px;
  background: #F0F4F5 !important;
  min-width: 90px;
  text-align: center;
}
#participantSelector .qtyminus {
  border-radius: 5px 0 0 5px !important;
  background: #ffffff !important;
  border-color: #C9CACA !important;
  padding: 1rem 1.5rem !important;
}
#participantSelector .qtyplus {
  border-radius: 0 5px 5px 0 !important;
  background: #ffffff !important;
  border-color: #C9CACA !important;
  padding: 1rem 1.5rem !important;
}

.footer-padding {
  padding-bottom: 100px;
}

.ui.primary.button {
  margin: 0;
}

#special-message {
  display: flex;
  padding: 0;
}
#special-message .ui.grid {
  margin: auto 0;
}
#special-message .special-button {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
}
#special-message.ui.info.message .initial {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  background: #2185d0;
  color: #ffffff;
  border: none;
  margin-bottom: 0.5px;
  font-size: 0.85rem;
  min-height: 70px;
  width: 100%;
}
#special-message.ui.info.message .initial label {
  padding: 0;
  font-size: 0.85rem;
  text-transform: uppercase;
  color: #ffffff;
}
#special-message.ui.info.message .accept {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 0 0.28571429rem 0 0;
  background: #2185d0;
  color: #ffffff;
  border: none;
  margin-bottom: 0.5px !important;
  font-size: 0.85rem !important;
  min-height: 50px;
  width: 100%;
}
#special-message.ui.info.message .decline {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0.28571429rem 0;
  background: #FFFFFF;
  color: #2185d0;
  margin-top: 0.5px !important;
  font-size: 0.85rem !important;
  min-height: 50px;
  width: 100%;
}
#special-message.ui.positive.message .initial {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  background: #2C662D;
  border: none;
  margin-bottom: 0.5px;
  font-size: 0.85rem;
  min-height: 70px;
  width: 100%;
}
#special-message.ui.positive.message .initial .header {
  font-family: "Allura", cursive;
  color: #ffffff;
  opacity: 1;
  font-weight: 300;
  letter-spacing: 5px;
  margin-top: 0.2px;
}
#special-message.ui.positive.message .accept[type=button] {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  background: #2C662D;
  color: #ffffff;
  border: none;
  margin-bottom: 0.5px !important;
  font-size: 0.85rem !important;
  text-transform: uppercase;
  margin-bottom: 0.5px;
  font-size: 0.85rem;
  min-height: 70px;
  width: 100%;
}
#special-message.ui.error.message .accept[type=button] {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  background: #9F3A38;
  color: #ffffff;
  border: none;
  margin-top: 0.5px !important;
  font-size: 0.85rem !important;
  text-transform: uppercase;
  margin-bottom: 0.5px;
  font-size: 0.85rem;
  min-height: 70px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  #special-message {
    display: flex;
    padding: 0;
  }
  #special-message .ui.stackable.grid > .row > .column, #special-message .ui.stackable.grid > .row > .wide.column {
    padding: 1rem !important;
  }
  #special-message .ui.stackable.grid > .row > .column.special-button, #special-message .ui.stackable.grid > .row > .wide.column.special-button {
    padding: 0 !important;
  }
  #special-message .ui.grid {
    margin: auto 0;
  }
  #special-message .special-button {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    height: auto;
  }
  #special-message.ui.info.message .initial {
    display: flex;
    height: 44px;
    min-height: 44px;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 0.28571429rem 0.28571429rem;
    background: #2185d0;
    color: #ffffff;
    border: none;
    margin-bottom: 0.5px;
    font-size: 0.85rem;
  }
  #special-message.ui.info.message .initial label {
    padding: 0;
    font-size: 0.85rem;
    text-transform: uppercase;
    color: #ffffff;
  }
  #special-message.ui.info.message .accept {
    display: flex;
    height: 44px;
    min-height: 44px;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    background: #2185d0;
    border: none;
    margin-bottom: 0.5px !important;
    font-size: 0.85rem !important;
  }
  #special-message.ui.info.message .decline {
    height: 44px;
    min-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 0.28571429rem 0.28571429rem;
    background: #FFFFFF;
    color: #2185d0;
    border: 1px solid #2185d0;
    margin-top: 0.5px !important;
    font-size: 0.85rem !important;
  }
  #special-message.ui.positive.message .initial {
    display: flex;
    height: 44px;
    min-height: 44px;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 0.28571429rem 0.28571429rem;
    background: #2C662D;
    border: none;
  }
  #special-message.ui.positive.message .initial .header {
    font-family: "Allura", cursive;
    color: #ffffff;
    opacity: 1;
    font-weight: 300;
    letter-spacing: 5px;
    margin-top: 0.2px;
  }
  #special-message.ui.positive.message .accept[type=button] {
    display: flex;
    height: 44px;
    min-height: 44px;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 0.28571429rem 0.28571429rem;
    background: #2C662D;
    color: #ffffff;
    border: none;
    margin-bottom: 0.5px !important;
    font-size: 0.85rem !important;
    text-transform: uppercase;
  }
  #special-message.ui.error.message .accept[type=button] {
    height: 44px;
    min-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 0.28571429rem 0.28571429rem;
    background: #9F3A38;
    color: #ffffff;
    border: none;
    margin-top: 0.5px !important;
    font-size: 0.85rem !important;
    text-transform: uppercase;
  }
}
.participantImage {
  max-height: 120px;
}

@media only screen and (max-width: 767px) {
  .selectParticipant {
    padding: 1rem 0 !important;
  }
}
.radioButton {
  min-height: 65px;
  background: #F0F4F5 !important;
}
@media only screen and (max-width: 767px) {
  .radioButton {
    min-height: 0;
  }
}

.centerList {
  text-align: center;
  list-style-position: inside;
  padding: 0;
}
.centerList li {
  padding-bottom: 1rem;
  width: 70%;
  margin: 0 auto;
}

.noPadding {
  padding: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

.unvisible {
  visibility: hidden;
  width: 0 !important;
  height: 0 !important;
}

.flex {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.selectBtn {
  margin: 20px 0 0 0 !important;
  width: 100%;
  border-radius: 0 0 3px 3px !important;
  text-align: center;
  height: 50px;
  background-color: #4883c3;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.fullHeight, .fullHeight div.field {
  height: 100%;
}

.pointer {
  cursor: pointer !important;
}

.selectedParticipant {
  border: 1px solid #6fa861 !important;
}

.selectedParticipant .selectBtn {
  background-color: #6fa861;
}

.smallText {
  font-weight: 400 !important;
}

label.flex img {
  padding-bottom: 5px;
}

label.pointer.smallText {
  height: 10px;
}

.participantCard {
  height: 250px !important;
}

.flexColumn {
  display: flex !important;
  height: 100%;
  margin: 0 !important;
  width: 100%;
}

.flex .flexColumn .pointer {
  padding-top: 10px;
}

@media only screen and (max-width: 769px) {
  .participantCard {
    height: 220px !important;
    justify-content: center;
  }
}
.textarea {
  display: inline-block;
  min-height: 12em;
  width: 100%;
  -moz-box-sizing: border-box;
  background: #F0F4F5;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3125em;
  box-shadow: 0 0 rgba(0, 0, 0, 0.3) inset;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1em;
  margin: 0;
  outline: medium none;
  padding: 0.65em 1em;
  transition: background-color 0.3s ease-out 0s, box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;
  text-align: left;
  vertical-align: top;
}

#labelMargin {
  margin-top: 1em !important;
}

select.ui.dropdown,
div.ui.dropdown.selection,
.ui.form input:not([type]), .ui.form input[type=text], .ui.form input[type=email], .ui.form input[type=search], .ui.form input[type=password], .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=tel], .ui.form input[type=time], .ui.form input[type=url], .ui.form input[type=number] {
  background: #F0F4F5 !important;
}

.dropdown, .ui.form .inline.field > input,
.ui.form .inline.fields .field > input,
.ui.form textarea,
.ui.textarea {
  width: 100%;
}

.ui.form .fields > .field {
  padding-left: 0;
  padding-right: 0;
}

.ui.form textarea, .ui.textarea {
  display: inline-block;
  height: auto;
  background: #F0F4F5;
}

.ui.basic.red.label {
  border: none;
  margin-top: 0 !important;
}

.ui.form .field .prompt.label {
  background: transparent !important;
  border: none !important;
  margin: 0 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  color: #e80404 !important;
}
.ui.form .field .prompt.label:before {
  content: none !important;
}

.ui.form .field.error label {
  color: inherit !important;
}

.ui.form .field.error input:not([type]), .ui.form .field.error input[type=date], .ui.form .field.error input[type=url], .ui.form .field.error input[type=datetime-local], .ui.form .field.error input[type=email], .ui.form .field.error input[type=number], .ui.form .field.error input[type=password], .ui.form .field.error input[type=search], .ui.form .field.error input[type=tel], .ui.form .field.error input[type=time], .ui.form .field.error input[type=text], .ui.form .field.error input[type=file], .ui.form .field.error select, .ui.form .field.error textarea, .ui.form .fields.error .field input:not([type]), .ui.form .fields.error .field input[type=date], .ui.form .fields.error .field input[type=url], .ui.form .fields.error .field input[type=datetime-local], .ui.form .fields.error .field input[type=email], .ui.form .fields.error .field input[type=number], .ui.form .fields.error .field input[type=password], .ui.form .fields.error .field input[type=search], .ui.form .fields.error .field input[type=tel], .ui.form .fields.error .field input[type=time], .ui.form .fields.error .field input[type=text], .ui.form .fields.error .field input[type=file], .ui.form .fields.error .field select, .ui.form .fields.error .field textarea {
  border-color: #e80404 !important;
  color: #e80404 !important;
}

.ui.pointing.label:before {
  content: none;
}

.margin-segment {
  margin: 2rem 3rem 2rem 2rem;
}

.description {
  display: block;
  font-weight: 400;
  padding: 0;
  margin: -7px 0 7px 0;
  line-height: 1.2em;
  color: rgba(0, 0, 0, 0.6);
}

.fieldContainer {
  display: flex;
  flex-direction: row;
  margin: 3px 0 3px 0;
  padding: 0;
}

.fieldLabel {
  display: flex;
  flex-direction: row;
  margin: 0 10px 0 0;
  padding: 10px 0 10px 0;
  justify-content: flex-end;
  text-align: end;
  width: 150px;
}

.fieldContent {
  background-color: #F0F4F5;
  margin: 0 10px 0 0;
  font-weight: 700;
  padding: 10px 0 10px 10px;
  width: 100%;
}

@media only screen and (max-width: 769px) {
  .fieldContainer {
    display: flex;
    flex-direction: column;
    margin: 3px 0 3px 0;
    padding: 0;
    width: 100%;
  }

  .fieldLabel {
    display: flex;
    flex-direction: row;
    margin: 0 10px 0 0;
    padding: 10px 0 10px 0;
    justify-content: flex-start;
    text-align: start;
    width: 150px;
  }
}
.document-signing {
  max-width: 900px;
}
.document-signing .ui.grid {
  margin: 0 !important;
}

@media only screen and (max-width: 767px) {
  .ui.very.padded.segment {
    padding: 1.5em !important;
    height: 100%;
  }

  .ui.fluid.button {
    margin-bottom: 1em;
  }

  .ui.button {
    width: 100%;
    margin-bottom: 0.5rem;
    font-size: 1.14285714rem;
    font-weight: 400;
  }

  .ui.stackable.grid > .row > .column, .ui.stackable.grid > .row > .wide.column {
    padding: 1rem 0 !important;
  }

  .eleven.wide.field {
    margin-top: 0.5rem !important;
  }

  .ui.items {
    margin: 0.5em 0;
  }

  .ui.items:not(.unstackable) > .item {
    margin: 1em 0 0;
  }

  .ui.checkbox {
    width: 100%;
  }

  .ui.modal .actions > .button {
    margin-left: 0;
  }

  .ui.compact.button {
    padding: 0.78571429em 1.5em;
  }

  .ui.mini.button {
    font-size: 1rem;
    margin-bottom: 0;
  }

  .ui.grid > .row > [class*="right aligned"].column.column {
    text-align: left;
  }

  .ui.stackable.grid > .row > .column, .ui.stackable.grid > .row > .wide.column {
    padding: 0.5rem 0 !important;
  }

  .ui.form .field > label {
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
  }

  .ui.input {
    height: 43px;
  }

  .ui.input,
.default.text,
.ui.dropdown .text,
div.field input {
    font-size: 16px !important;
  }

  .ui.message p {
    opacity: 1;
  }

  .ui.stackable.grid > .column:not(.row) {
    padding: 0.5rem 0 !important;
  }
}
button {
  margin-bottom: 1rem !important;
}

.ui.form .warning.message {
  display: block;
}

.ui.basic.primary.button {
  box-shadow: none !important;
}
.ui.basic.primary.button:hover {
  box-shadow: none !important;
}

.ui.text.container {
  width: 550px;
}

.ui.basic.primary.button {
  text-transform: capitalize !important;
  padding-right: 0;
}

h4.ui.header .sub.header {
  font-size: 0.9rem;
}

.ui.segment {
  box-shadow: none;
}

h1.ui.header {
  line-height: 2.2rem;
}

.ui.primary.button {
  font-weight: 400;
  font-size: 1rem !important;
  padding: 1rem 1.5rem;
}

.ui.secondary.button {
  font-weight: 400;
  font-size: 1rem !important;
  padding: 1rem 1.5rem;
}

.ui.big.button {
  font-size: 1.15rem !important;
}
